<template>
  <div v-if="isCollect" class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>收票中心</span>
        </div>
        <div class="select-items">
          <div v-role="'RC-F01'" @click="handleManualInput">
            <img src="@/assets/img/invoiceCenter/manual@2x.png" />手工收票
          </div>
          <div v-role="'RC-F01'" @click="handleScanningGun">
            <img src="@/assets/img/invoiceCenter/scanner_gun.png" />扫码收票
          </div>
          <div v-role="'RC-F02'" @click="handleScannerScan">
            <img src="@/assets/img/invoiceCenter/scanner.png" />扫描收票
          </div>
          <div v-role="'RC-F03'" @click="handleLocalPictures">
            <img src="@/assets/img/invoiceCenter/file.png" />上传收票
          </div>
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectFrom" :model="search" label-width="160px" inline>
          <el-form-item label="发票代码">
            <el-input
              v-model.trim="search.invoiceCode"
              placeholder="请输入发票代码"
              maxlength="12"
              @keyup.enter.native="handleGetInvoiceData()"
              clearable
            />
          </el-form-item>
          <el-form-item label="发票号码">
            <el-input
              v-model.trim="search.invoiceNo"
              placeholder="请输入发票号码"
              maxlength="8"
              @keyup.enter.native="handleGetInvoiceData()"
              clearable
            />
          </el-form-item>
          <el-form-item label="收票日期">
            <el-date-picker
              class="date-picker"
              v-model="search.receiptDate"
              :picker-options="dateOption"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <span v-show="selectExpended">
            <el-form-item label="验章状态">
              <el-select
                v-model="search.sealStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in sealStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="识别批次">
              <el-input
                v-model.trim="search.batchNo"
                placeholder="请输入识别批次"
                maxlength="64"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="开票日期">
              <el-date-picker
                class="date-picker"
                v-model="search.invoiceTime"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="dateOption"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="是否启用综合服务平台">
              <el-select
                v-model="search.isUseIncomeVerifyPlatform"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" :value="undefined">全部</el-option>
                <el-option label="是" :value="true">是</el-option>
                <el-option label="否" :value="false">否</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="结算状态">
              <el-select
                v-model="search.settlementStatus"
                placeholder="请选择结算状态"
                clearable
              >
                <el-option
                  v-for="item in settlementStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票说明">
              <el-input
                v-model.trim="search.invoiceExplain"
                placeholder="请输入发票说明"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票查验平台查验状态">
              <el-select
                v-model="search.verifyStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in verifyStatusSet"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="购方名称">
              <el-input
                v-model.trim="search.buyerName"
                placeholder="请输入购方名称"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="购方信息稽核">
              <el-select
                v-model="search.buyerInfoAudit"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in buyerInfoAuditArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用发票查验平台">
              <el-select
                v-model="search.isUseVerifyPlatform"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" :value="undefined">全部</el-option>
                <el-option label="是" :value="true">是</el-option>
                <el-option label="否" :value="false">否</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销方名称">
              <el-input
                v-model.trim="search.sellerName"
                placeholder="请输入销方名称"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票类型">
              <el-select
                @change="invoiceTypeSetChange"
                multiple
                collapse-tags
                v-model="search.invoiceTypeList"
                placeholder="请选择发票类型"
                clearable
              >
                <el-option
                  :disabled="item.disabled"
                  v-for="item in invoiceTypeAllSet"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="综合服务平台查验状态">
              <el-select
                v-model="search.incomeVerifyStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in verifyStatusSet"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否下载">
              <el-select
                v-model="search.ifDownload"
                placeholder="请选择是否下载"
                clearable
              >
                <el-option
                  v-for="item in IfDownloadList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有无备注">
              <el-select
                v-model="search.ifHasRemark"
                placeholder="请选择有无备注"
                clearable
              >
                <el-option
                  v-for="item in IfRemarkList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input
                v-model.trim="search.remark"
                placeholder="请输入备注信息"
                maxlength="50"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发件人">
              <el-input
                v-model.trim="search.sender"
                placeholder="请输入发件人"
                maxlength="50"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="收票类型">
              <el-select
                v-model="search.channel"
                placeholder="请选择收票类型"
                clearable
              >
                <el-option
                  v-for="item in TypeOfReceiptList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数电发票号码">
              <el-input
                maxlength="20"
                v-model.trim="search.eInvoiceNo"
                placeholder="请输入数电发票号码"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票状态">
              <el-select
                v-model="search.invoiceStatus"
                placeholder="请选择发票状态"
                clearable
              >
                <el-option
                  v-for="item in invoiceStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="勾选状态">
              <el-select
                v-model="search.isCheck"
                placeholder="请选择勾选状态"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已勾选" value="1"></el-option>
                <el-option label="未勾选" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抵扣状态">
              <el-select
                v-model="search.deductionStatus"
                placeholder="请选择抵扣状态"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未抵扣" value="0"></el-option>
                <el-option label="抵扣" value="1"></el-option>
                <el-option label="退税" value="2"></el-option>
                <el-option label="不抵扣" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票来源">
              <el-select
                v-model="search.receiveFrom"
                placeholder="请选择发票来源"
                clearable
              >
                <el-option
                  v-for="item in receiveFromList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="匹配状态">
              <el-select
                v-model="search.matchStatus"
                placeholder="请选择匹配状态"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未匹配" value="0"></el-option>
                <el-option label="已匹配" value="1"></el-option>
                <el-option label="匹配失败" value="2"></el-option>
              </el-select>
            </el-form-item>
          </span>
          <el-form-item>
            <select-button
              show-status
              @list-close="handleListClose"
              @list-show="handleListShow"
              @select="handleQuery"
              @reset="handleReset"
            >
            </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>票据列表</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选
              </el-checkbox>
              <div class="part"></div>
              <el-checkbox
                v-for="(pv, pk) in columns"
                :label="pv.label"
                :key="pk"
                v-model="pv.visible"
                @change="handleCheckedCitiesChange(pk, pv)"
              />
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
            <el-dropdown @command="handleCommand" style="margin-right: 10px">
              <el-button type="default">
                更多操作<i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="changeDept"
                  >变更收票组织</el-dropdown-item
                >
                <el-dropdown-item command="pushInvoice"
                  >执行推送发票</el-dropdown-item
                >
                <el-dropdown-item command="auditBatch"
                  >稽核购方信息</el-dropdown-item
                >
                <el-dropdown-item command="editorExplainBatch"
                  >编辑发票说明</el-dropdown-item
                >
                <el-dropdown-item command="settlementStatusUpdateBatch"
                  >修改结算状态</el-dropdown-item
                >
                <el-dropdown-item command="batchGeneratePdfBatch"
                  >生成影像文件</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="success" @click="handleVerifyInvoice"
              >查验发票</el-button
            >
            <el-button type="danger" @click="handleInvoiceBatchDelete"
              >删除发票</el-button
            >
            <el-button
              type="info"
              @click="handleDownFile"
              :loading="downLoading"
              >下载影像</el-button
            >
            <el-button
              type="info"
              @click="handleExportConfirmation"
              :loading="exportLoading"
              >导出发票</el-button
            >
            <el-button type="info" @click="invoiceImportVisible = true"
              >导入发票</el-button
            >
          </div>
        </div>
        <div class="content-table">
          <el-table
            ref="invoiceCenterTable"
            :data="tableData"
            stripe
            border
            show-summary
            :summary-method="getSummaries"
            v-loading="tableLoading"
            :header-cell-style="handleHeaderCellStyle"
            @selection-change="handleSelection"
            style="width: 100%"
          >
            <el-table-column type="selection" width="50" fixed="left" />
            <el-table-column
              type="index"
              label="序号"
              width="50"
              fixed="left"
            />
            <el-table-column
              v-if="columns['invoiceType'].visible"
              prop="invoiceType"
              :label="columns['invoiceType'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceCode'].visible"
              prop="invoiceCode"
              :label="columns['invoiceCode'].label"
              width="120"
            />
            <el-table-column
              v-if="columns['invoiceNo'].visible"
              prop="invoiceNo"
              :label="columns['invoiceNo'].label"
              width="100"
            />
            <el-table-column
              v-if="columns['eInvoiceNo'].visible"
              prop="eInvoiceNo"
              :label="columns['eInvoiceNo'].label"
              min-width="200"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['sumAmount'].visible"
              prop="sumAmount"
              :label="columns['sumAmount'].label"
              width="200"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['amount'].visible"
              prop="amount"
              :label="columns['amount'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['taxAmount'].visible"
              prop="taxAmount"
              :label="columns['taxAmount'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['buyerName'].visible"
              prop="buyerName"
              :label="columns['buyerName'].label"
              min-width="200"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['buyerInfoAudit'].visible"
              prop="buyerInfoAudit"
              :label="columns['buyerInfoAudit'].label"
              width="120"
              :formatter="handleTableValueFormat"
            >
              <template slot="header" slot-scope="scope">
                <div>
                  <span>购方信息稽核</span>
                  <el-tooltip placement="top">
                    <div slot="content" v-html="tips"></div>
                    <i class="el-icon-question icon"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['sellerName'].visible"
              prop="sellerName"
              :label="columns['sellerName'].label"
              min-width="200"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceTime'].visible"
              prop="invoiceTime"
              :label="columns['invoiceTime'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveTime'].visible"
              prop="receiveTime"
              :label="columns['receiveTime'].label"
              width="180"
            />
            <!-- 查验方式 -->
            <el-table-column
              v-if="columns['isUseVerifyPlatform'].visible"
              prop="isUseVerifyPlatform"
              :label="columns['isUseVerifyPlatform'].label"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.isUseVerifyPlatform ? "是" : "否" || "--"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['verifyStatus'].visible"
              prop="verifyStatus"
              :label="columns['verifyStatus'].label"
              width="180"
            >
              <template slot-scope="scope">
                <div
                  :style="{
                    color: handleVerifyAndSealStatusColor(
                      scope.row.verifyStatus
                    ),
                  }"
                >
                  <span v-if="scope.row.verifyStatus != 'FAILURE'">
                    {{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.verifyStatus
                      )
                    }}
                  </span>
                  <el-tooltip v-else class="item" effect="dark" placement="top">
                    <div slot="content">
                      <span>{{ scope.row.verifyMessage }}</span>
                    </div>
                    <span>{{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.verifyStatus
                      )
                    }}</span>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['isUseIncomeVerifyPlatform'].visible"
              prop="isUseIncomeVerifyPlatform"
              :label="columns['isUseIncomeVerifyPlatform'].label"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.isUseIncomeVerifyPlatform ? "是" : "否" || "--"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['incomeVerifyStatus'].visible"
              prop="incomeVerifyStatus"
              :label="columns['incomeVerifyStatus'].label"
              width="180"
            >
              <template slot-scope="scope">
                <div
                  :style="{
                    color: handleVerifyAndSealStatusColor(
                      scope.row.incomeVerifyStatus
                    ),
                  }"
                >
                  <span v-if="scope.row.incomeVerifyStatus != 'FAILURE'">
                    {{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.incomeVerifyStatus
                      )
                    }}
                  </span>
                  <el-tooltip v-else class="item" effect="dark" placement="top">
                    <div slot="content">
                      <span>{{ scope.row.incomeVerifyMessage }}</span>
                    </div>
                    <span>{{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.incomeVerifyStatus
                      )
                    }}</span>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              v-if="columns['remark'].visible"
              prop="remark"
              :label="columns['remark'].label"
              width="320"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['returnSuccess'].visible"
              prop="returnSuccess"
              :label="columns['returnSuccess'].label"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.returnSuccess == 'Y'">
                  {{ formatReturnSuccessEnums(scope.row.returnSuccess) }}
                </span>
                <el-tooltip v-else effect="dark" placement="top">
                  <div slot="content">
                    <span>{{ scope.row.returnMessage }}</span>
                  </div>
                  <span>{{
                    formatReturnSuccessEnums(scope.row.returnSuccess)
                  }}</span>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              v-if="columns['invoiceStatus'].visible"
              prop="invoiceStatus"
              :label="columns['invoiceStatus'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['sealStatus'].visible"
              prop="sealStatus"
              :label="columns['sealStatus'].label"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  :style="{
                    color: handleVerifyAndSealStatusColor(scope.row.sealStatus),
                  }"
                >
                  <span>{{
                    handleValueToLabel(
                      "InvoiceSealStatus",
                      scope.row.sealStatus
                    )
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['settlementStatus'].visible"
              prop="settlementStatus"
              :label="columns['settlementStatus'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['auditStatus'].visible"
              prop="auditStatus"
              :label="columns['auditStatus'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['deductionStatus'].visible"
              prop="deductionStatus"
              :label="columns['deductionStatus'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['isExistList'].visible"
              prop="isExistList"
              :label="columns['isExistList'].label"
              width="120"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.row.isExistList"
                  style="color: #1890ff; cursor: pointer"
                  @click="handleInvoiceLists(scope.row)"
                >
                  {{
                    `是（${scope.row.listUploadedNum || 0}/${
                      scope.row.listTotal || 0
                    }）`
                  }}
                </div>
                <div v-else>否</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['batchNo'].visible"
              prop="batchNo"
              :label="columns['batchNo'].label"
              width="320"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['imageType'].visible"
              prop="imageType"
              :label="columns['imageType'].label"
              width="80"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['orgName'].visible"
              prop="orgName"
              show-overflow-tooltip
              :label="columns['orgName'].label"
              width="250"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['creatorName'].visible"
              prop="creatorName"
              :label="columns['creatorName'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceExplain'].visible"
              prop="invoiceExplain"
              :label="columns['invoiceExplain'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['ifDownload'].visible"
              prop="ifDownload"
              :label="columns['ifDownload'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.ifDownload == true ? "是" : "否" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['sender'].visible"
              prop="sender"
              :label="columns['sender'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['senderEmail'].visible"
              prop="senderEmail"
              :label="columns['senderEmail'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveTimeS'].visible"
              prop="receiveTime"
              :label="columns['receiveTimeS'].label"
              min-width="180"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['channel'].visible"
              prop="channel"
              :label="columns['channel'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['owneriod'].visible"
              prop="owneriod"
              :label="columns['owneriod'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['checkedTime'].visible"
              prop="checkedTime"
              :label="columns['checkedTime'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['matchStatus'].visible"
              prop="matchStatus"
              :label="columns['matchStatus'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveFrom'].visible"
              prop="receiveFrom"
              :label="columns['receiveFrom'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['operate'].visible"
              prop="operate"
              :label="columns['operate'].label"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"
                  >详情</el-button
                >
                <el-button type="text" @click="handleInvoiceUpdate(scope.row)"
                  >修改</el-button
                >
                <!-- <el-button type="text" :disabled="scope.row.verifyStatus != 'FAILURE'" @click="handleInvoiceUpdate(scope.row)">修改</el-button> -->
                <!-- <el-button type="text" :loading="scope.row.loading"  @click="handleInvoiceImage(scope.row)">影像</el-button> -->
                <el-button
                  type="text"
                  :loading="scope.row.loading"
                  :disabled="
                    (scope.row.invoiceFileId == 0 ||
                      scope.row.invoiceFileId == null) &&
                    (scope.row.invoiceDeductionFileId == 0 ||
                      scope.row.invoiceDeductionFileId == null)
                  "
                  @click="handleInvoiceImg(scope.row)"
                  >影像</el-button
                >

                <el-dropdown @command="handleCommandLine">
                  <span class="el-dropdown-link"
                    >更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="beforeHandleCommandLine(scope, 'againPush')"
                      >重新推送</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="
                        beforeHandleCommandLine(scope, 'settlementStatusEdit')
                      "
                      >结算状态</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="
                        beforeHandleCommandLine(scope, 'invoiceExplainEdit')
                      "
                      >编辑说明</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommandLine(scope, 'generate')"
                      >生成影像</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="beforeHandleCommandLine(scope, 'invoiceImage')"
                      >发票文件</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[15, 30, 50, 100]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 更新结算 -->
    <el-dialog
      append-to-body
      :visible.sync="settlementStatusUpdateVisible"
      width="35%"
      @close="handleSettlementStatusUpdateCancel"
      :show-close="false"
    >
      <div class="dialog-title">
        <div class="title">{{ settlementStatusUpdateTitle }}</div>
        <div class="message" v-if="isBatch">
          已勾选 {{ this.selections.length }} 张发票
        </div>
      </div>
      <div
        class="dialog-settlement"
        v-html="settlementStatusUpdateContent"
      ></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleSettlementStatusUpdateCancel">取消</el-button>
        <el-button type="primary" @click="handleSettlementStatusUpdateConfirm()"
          >变更</el-button
        >
      </div>
    </el-dialog>

    <!-- 生成影像 -->
    <el-dialog
      append-to-body
      :visible.sync="batchGeneratePdfVisible"
      width="35%"
      @close="handleBatchGeneratePdfCancel"
      :show-close="false"
    >
      <div class="dialog-title">
        <div class="title">{{ batchGeneratePdfTitle }}</div>
      </div>
      <div class="dialog-settlement" v-html="batchGeneratePdfContent"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleBatchGeneratePdfCancel">取消</el-button>
        <el-button
          :disabled="this.needToGeneratePdfCount == 0"
          type="primary"
          @click="handleBatchGeneratePdfConfirm()"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑说明 -->
    <el-dialog
      append-to-body
      title="编辑发票说明"
      :visible.sync="editVisibleExplain"
      width="34%"
      @close="updateInvoiceExplainCancel()"
    >
      <div>
        <el-input
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          v-model="invoiceExplain"
        ></el-input>
        <div
          style="
            color: red;
            font-size: 12px;
            text-align: right;
            margin-top: 20px;
          "
          v-if="explainWarning"
        >
          已选发票的说明信息并非全部一致，请谨慎修改。
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateInvoiceExplainCancel()">取 消</el-button>
        <el-button type="primary" @click="updateInvoiceExplain()"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 录入收票 -->
    <el-dialog
      append-to-body
      title="手工录入"
      :visible.sync="manualVisible"
      width="580px"
      @close="handleManualInputCancel('manualInput')"
    >
      <div class="manual-input">
        <el-form
          ref="manualInput"
          :model="manualInputContent"
          :rules="rules"
          label-position="top"
        >
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="收票组织" prop="orgId">
                <organization-select
                  :model="manualInputContent"
                  allLabel="请选择收票组织"
                  :is-add-all="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="发票代码" prop="invoiceCode">
                <el-input
                  v-model="manualInputContent.invoiceCode"
                  placeholder="请输入发票代码"
                  maxlength="12"
                  @blur="getInvoiceTypeFromInvoiceCode"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="发票号码" prop="invoiceNo">
                <el-input
                  v-model="manualInputContent.invoiceNo"
                  placeholder="请输入发票号码"
                  maxlength="8"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="发票类型" prop="invoiceType">
                <el-select
                  filterable
                  v-model="manualInputContent.invoiceType"
                  placeholder="请选择发票类型"
                  clearable
                >
                  <el-option
                    v-for="item in manualInvoiceTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="11">
              <el-form-item label="开票日期" prop="invoiceTime">
                <el-date-picker
                  v-model="manualInputContent.invoiceTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerDisabled"
                  placeholder="请选择开票时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="hasAmount(manualInputContent)">
              <el-form-item
                label="金额（不含税）"
                prop="amount"
                v-if="hasAmount(manualInputContent)"
              >
                <el-input
                  v-model="manualInputContent.amount"
                  placeholder="请输入金额"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            type="flex"
            justify="space-between"
            v-if="hasCheckCode(manualInputContent)"
          >
            <el-col :span="11">
              <el-form-item label="校验码后6位" prop="verifyCode">
                <el-input
                  v-model="manualInputContent.verifyCode"
                  placeholder="请输入校验码后6位"
                  maxlength="6"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleManualInputCancel('manualInput')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="handleManualInputConfirm('manualInput')"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <!-- 手机扫描 -->
    <el-dialog
      append-to-body
      title="手机扫描"
      :visible.sync="phoneVisible"
      width="33%"
    >
      <div class="phone-scan">
        <div class="phone-scan-text">
          <span><b>第一步：</b>微信搜索“多啦收票宝”小程序</span>
          <span><b>第二步：</b>点击“抵扣收票”</span>
          <span
            ><b>第三步：</b
            >扫描下方二维码，即可用手机代替扫描仪OCR识别收专票</span
          >
        </div>
        <div class="phone-scan-img">
          <el-image :src="qrCodeImg" fit="scale-down" lazy />
        </div>
      </div>
    </el-dialog>

    <!-- 发票详情 -->
    <invoice-detail
      :loading="loading"
      :show.sync="drawer"
      :invoice="invoice"
    ></invoice-detail>

    <!-- 修改发票 -->
    <el-dialog
      append-to-body
      fullscreen
      title="修改发票"
      :visible.sync="updateVisible"
      @close="handleInvoiceUpdateCancel('invoiceUpdate')"
    >
      <cusMessage />
      <div class="photo_box" v-show="isShowPhoto">
        <DragScaleImg :src="imgPre" v-if="imgPre" />
      </div>
      <div class="tabs_box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="主要信息" name="master" class="invoice-update">
            <el-form
              ref="invoiceUpdate"
              :model="invoiceUpdate"
              :rules="rules"
              label-position="top"
            >
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="发票类型" prop="invoiceType">
                    <el-select
                      v-model="invoiceUpdate.invoiceType"
                      placeholder="请选择发票类型"
                    >
                      <el-option
                        v-for="item in invoiceTypeSet"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="发票代码"
                    :prop="
                      this.invoiceUpdate.invoiceType == 'FullEleSpecInvoice' ||
                      this.invoiceUpdate.invoiceType == 'FullEleGenerInvoice'
                        ? ''
                        : 'invoiceCode'
                    "
                  >
                    <el-input
                      :disabled="
                        this.invoiceUpdate.invoiceType ==
                          'FullEleSpecInvoice' ||
                        this.invoiceUpdate.invoiceType == 'FullEleGenerInvoice'
                          ? true
                          : false
                      "
                      v-model="invoiceUpdate.invoiceCode"
                      placeholder="请输入发票代码"
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>

                <el-col
                  :span="6"
                  v-if="
                    (!isInvoiceList &&
                      this.invoiceUpdate.invoiceType == 'FullEleSpecInvoice') ||
                    this.invoiceUpdate.invoiceType == 'FullEleGenerInvoice'
                      ? false
                      : true
                  "
                >
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input
                      v-model="invoiceUpdate.invoiceNo"
                      placeholder="请输入发票号码"
                      maxlength="8"
                    />
                  </el-form-item>
                </el-col>

                <el-col
                  :span="6"
                  v-if="
                    (!isInvoiceList &&
                      this.invoiceUpdate.invoiceType == 'FullEleSpecInvoice') ||
                    this.invoiceUpdate.invoiceType == 'FullEleGenerInvoice'
                      ? true
                      : false
                  "
                >
                  <el-form-item label="数电发票号码" prop="eInvoiceNo">
                    <el-input
                      v-model="invoiceUpdate.eInvoiceNo"
                      placeholder="请输入发票号码"
                      maxlength="20"
                    />
                  </el-form-item>
                </el-col>

                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="开票时间" placeholder="请输入开票时间">
                    <el-date-picker
                      v-model="invoiceUpdate.invoiceTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerDisabled"
                      placeholder="请选择时间"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item label="金额（不含税）" prop="amount">
                    <el-input
                      v-model="invoiceUpdate.amount"
                      placeholder="请输入金额"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!hasCheckCode(invoiceUpdate)">
                  <el-form-item label="发票状态" prop="invoiceStatus">
                    <el-select
                      v-model="invoiceUpdate.invoiceStatus"
                      placeholder="请选择发票状态"
                    >
                      <el-option
                        v-for="item in invoiceStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item prop="isExistList" label="是否有清单">
                    <el-select v-model="invoiceUpdate.isExistList">
                      <el-option
                        v-for="item in isExistListArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="hasCheckCode(invoiceUpdate)">
                  <el-form-item label="校验码后6位" prop="verifyCode">
                    <el-input
                      v-model="invoiceUpdate.verifyCode"
                      placeholder="请输入校验码后6位"
                      maxlength="6"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList">
                  <el-form-item prop="imageChineseKey" label="发票联次">
                    <el-radio
                      v-model="invoiceUpdate.imageChineseKey"
                      label="发票联"
                      >发票联</el-radio
                    >
                    <el-radio
                      v-model="invoiceUpdate.imageChineseKey"
                      label="抵扣联"
                      >抵扣联</el-radio
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col
                  :span="6"
                  v-if="
                    !isInvoiceList &&
                    [
                      'FullEleSpecInvoice',
                      'FullEleGenerInvoice',
                      'ElePlaneInvoice',
                      'EleTrainInvoice',
                      'FullElePaperSpecInvoice',
                      'FullElePaperGenerInvoice',
                    ].indexOf(this.invoiceUpdate.invoiceType) != -1
                  "
                >
                  <el-form-item label="数电票号">
                    <el-input
                      v-model="invoiceUpdate.eInvoiceNo"
                      placeholder="请输入数电票号"
                      maxlength="20"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="备注信息">
                    <el-input
                      v-model="invoiceUpdate.remark"
                      placeholder="请输入备注信息"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="!isInvoiceList"> </el-col>
                <el-col :span="6" v-if="!isInvoiceList"> </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="发票号码" prop="invoiceNo">
                    <el-input
                      v-model="invoiceUpdate.invoiceNo"
                      placeholder="请输入发票号码"
                      maxlength="8"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="总页数" prop="pageSize">
                    <el-input
                      v-model="invoiceUpdate.pageSize"
                      placeholder="请输入总页数"
                      maxlength="8"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="isInvoiceList">
                  <el-form-item label="当前页码" prop="pageIndex">
                    <el-input
                      v-model="invoiceUpdate.pageIndex"
                      placeholder="请输入当前页码"
                      maxlength="8"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="审核信息" name="shenhe" class="invoice-update">
            <el-form label-position="top" :model="invoiceUpdate" :rules="rules">
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="开票人" prop="payer">
                    <el-input
                      v-model="invoiceUpdate.payer"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否盖章" prop="invoiceSeal">
                    <el-select v-model="invoiceUpdate.invoiceSeal">
                      <el-option
                        v-for="item in isInvoiceSealArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印代码" prop="printedCode">
                    <el-input
                      v-model="invoiceUpdate.printedCode"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="复核人" prop="auditor">
                    <el-input
                      v-model="invoiceUpdate.auditor"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="收款人" prop="payee">
                    <el-input
                      v-model="invoiceUpdate.payee"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="印刷代码" prop="printedBrushCode">
                    <el-input
                      v-model="invoiceUpdate.printedBrushCode"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="打印号码" prop="printedNo">
                    <el-input
                      v-model="invoiceUpdate.printedNo"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item label="印刷号码" prop="printedBrushNo">
                    <el-input
                      v-model="invoiceUpdate.printedBrushNo"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票信息" name="kaipiao" class="invoice-update">
            <el-form label-position="top" :model="invoiceUpdate" :rules="rules">
              <h5 class="kp_title">购方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="buyerName">
                    <el-input
                      v-model="invoiceUpdate.buyerName"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="buyerTaxNo">
                    <el-input
                      v-model="invoiceUpdate.buyerTaxNo"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="buyerAddressTel">
                    <el-input
                      v-model="invoiceUpdate.buyerAddressTel"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="buyerBankAccount">
                    <el-input
                      v-model="invoiceUpdate.buyerBankAccount"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <h5 class="kp_title" style="margin-top: 40px">销方信息</h5>
              <el-row type="flex" justify="space-between">
                <el-col :span="6">
                  <el-form-item label="名称" prop="sellerName">
                    <el-input
                      v-model="invoiceUpdate.sellerName"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="纳税人识别号" prop="sellerTaxNo">
                    <el-input
                      v-model="invoiceUpdate.sellerTaxNo"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="地址及电话" prop="sellerAddressTel">
                    <el-input
                      v-model="invoiceUpdate.sellerAddressTel"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开户行及账号" prop="sellerBankAccount">
                    <el-input
                      v-model="invoiceUpdate.sellerBankAccount"
                      placeholder=""
                      maxlength="12"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="开票明细" name="kpmx" class="invoice-update">
            <InvoiceOpenGoodsList
              class="incvoice_open_goods_list"
              ref="invoiceGoodsList"
              :goods-list="goodsList"
              biz-type="manual"
              @handleFocusTd="handleFocusTd"
              @handleInsertMerchandise="handleInsertMerchandise"
              @handleDiscount="handleDiscount"
              @changeIndex="changeIndex"
              @dataSelect="handleCurrentGoods"
              @handleShowGoods="handleShowGoods"
              @nameBlur="handleMerchandiseNameBlur"
              @reviewInvoice="handleReviewInvoice"
              @handleDeleteItems="handleDeleteItems"
            />
          </el-tab-pane>
        </el-tabs>
        <div
          class="show_photo_btn"
          style="position: absolute; top: 8px; right: 0"
        >
          显示影像&nbsp;&nbsp;<el-switch v-model="isShowPhoto"> </el-switch>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleInvoiceUpdateConfirm('invoiceUpdate')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 选择商品 -->
    <el-dialog
      append-to-body
      title="选择商品"
      :visible.sync="dialogGoodsListVisible"
      width="804px"
      :show-close="false"
    >
      <merchandise-list-dialog
        v-if="dialogGoodsListVisible"
        @handleCurrentGoods="handleCurrentGoods"
      ></merchandise-list-dialog>
    </el-dialog>

    <!-- 导入发票 -->
    <el-dialog
      append-to-body
      title="导入发票"
      :visible.sync="invoiceImportVisible"
      width="500px"
      :show-close="false"
    >
      <invoice-import
        @handReloadCustomerList="handReloadCustomerList"
        @handleCancelImportDialog="invoiceImportVisible = false"
      ></invoice-import>
    </el-dialog>

    <!-- 销货清单详情弹窗 -->
    <el-dialog
      append-to-body
      title="销货清单详情"
      :visible.sync="invoiceListVisible"
      width="600px"
      :close-on-press-escape="false"
    >
      <div class="dialog-invoice-list">
        <div class="list-statistics">
          <el-row>
            <el-col :span="12">
              <div>
                <label>匹配情况：</label>
                <span
                  >{{ invoiceListData.length }}/{{ invoiceListPageSize }}</span
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>总页数：</label>
                <span>{{ invoiceListPageSize }}页</span>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div>
                <label>未匹配页码：</label>
                <span>{{
                  invoiceListUnmatched.length == 0
                    ? "--"
                    : invoiceListUnmatched.toString()
                }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="list-table">
          <div class="order_sort">
            <div>已匹配信息列表</div>
            <div>
              <el-radio v-model="sortOrder" label="1">按扫描顺序排序</el-radio>
              <el-radio v-model="sortOrder" label="2">按清单顺序排序</el-radio>
            </div>
          </div>

          <el-table
            stripe
            border
            :data="invoiceListData"
            :max-height="280"
            :header-cell-style="handleHeaderCellStyle"
            @selection-change="handleSelection"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="70" />
            <el-table-column prop="invoiceCode" label="发票代码" width="120" />
            <el-table-column prop="invoiceNo" label="发票号码" width="100" />
            <el-table-column prop="pageSize" label="总页数" width="70" />
            <el-table-column prop="pageIndex" label="当前页数" width="70" />
            <el-table-column prop="operate" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleInvoiceListUpdatePopup(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleInvoiceListImage(scope.row)"
                  >影像</el-button
                >
                <el-button
                  type="text"
                  @click="handleInvoiceListDeletePopup(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 发票编辑 -->
      <el-dialog
        append-to-body
        title="发票清单编辑"
        :visible.sync="innerVisible"
        width="30%"
      >
        <div class="invoice-update">
          <el-form
            ref="invoiceListUpdate"
            :model="invoiceListUpdateData"
            :rules="rules"
            label-position="top"
          >
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="发票类型">
                  <el-select
                    v-model="invoiceListUpdateData.invoiceType"
                    :disabled="true"
                    placeholder="请选择发票类型"
                  >
                    <el-option
                      v-for="item in invoiceTypeSet"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="发票代码" prop="invoiceCode">
                  <el-input
                    v-model="invoiceListUpdateData.invoiceCode"
                    placeholder="请输入发票代码"
                    maxlength="12"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="发票号码" prop="invoiceNo">
                  <el-input
                    v-model="invoiceListUpdateData.invoiceNo"
                    placeholder="请输入发票号码"
                    maxlength="8"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="总页数" prop="pageSize">
                  <el-input
                    v-model="invoiceListUpdateData.pageSize"
                    placeholder="请输入总页数"
                    maxlength="8"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="当前页码" prop="pageIndex">
                  <el-input
                    v-model="invoiceListUpdateData.pageIndex"
                    placeholder="请输入当前页码"
                    maxlength="8"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleInvoiceListUpdateCancel('invoiceListUpdate')"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="handleInvoiceListUpdateConfirm('invoiceListUpdate')"
            >提交</el-button
          >
        </div>
      </el-dialog>

      <div class="dialog-footer" slot="footer">
        <el-button type="primary" @click="invoiceListVisible = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>

    <!-- 删除发票 -->
    <el-dialog
      append-to-body
      width="30%"
      :visible.sync="invoiceBatchDeleteVisible"
    >
      <div class="dialog-delete">
        <span>确认删除当前发票</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelDeleteBatch">取消</el-button>
        <el-button type="primary" @click="deleteBatch">确认</el-button>
      </div>
    </el-dialog>

    <!-- 批量推送 -->
    <el-dialog
      title="提示"
      append-to-body
      width="30%"
      :visible.sync="invoiceBatchPushVisible"
    >
      <div class="dialog-delete">
        <span>选择数据中含非数电发票是否需要推送！</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelPushBatch">取消</el-button>
        <el-button type="primary" @click="pushBatch">确认</el-button>
      </div>
    </el-dialog>

    <!-- 删除清单 -->
    <el-dialog
      append-to-body
      width="30%"
      :visible.sync="InvoiceListDeleteVisible"
    >
      <div style="margin: 30px 0">
        <span>确认删除当前发票清单</span>
      </div>
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button
          type="default"
          @click="cancelDeleteInvoiceList"
          class="button-left"
          >取消</el-button
        >
        <el-button type="primary" @click="deleteInvoiceListConfirm"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 导出发票 -->
    <el-dialog append-to-body width="35%" :visible.sync="exportConfirmation">
      <div style="margin: 10px 0">
        <p style="font-size: 20px">
          您已勾选{{ exportQuantity }}张票据，是否导出发票数据？
        </p>
        <p style="color: red">
          注：导出数据时，仅会按照<el-button
            style="
              color: blue;
              font-size: 20px;
              margin: 0px;
              border: 0px;
              padding: 0px;
            "
            @click="handleExportSettings"
            >导出设置</el-button
          >中已勾选的字段进行导出。
        </p>
      </div>
      <div slot="footer" class="button-footer" style="text-align: center">
        <el-button
          type="default"
          @click="cancelExportConfirmation"
          class="button-left"
          >取消</el-button
        >
        <el-button type="primary" @click="handleExportBatch">确认</el-button>
      </div>
    </el-dialog>

    <!-- 影像浏览 -->
    <el-image-viewer
      v-if="invoiceImageViewer"
      :url-list="invoiceImages"
      :on-close="handleImageViewerClose"
      style="z-index: 2020"
    />

    <!-- 影像预览 -->
    <el-dialog width="80%" title="发票影像" :visible.sync="invoiceImageVisible">
      <col-invoice-image
        v-if="invoiceImageVisible"
        :col-invoice-id="colInvoiceId"
      ></col-invoice-image>
    </el-dialog>

    <!-- 收票组织 -->
    <el-dialog
      title="变更收票组织"
      :visible.sync="takerOrganizationVisible"
      width="30%"
    >
      <el-form :model="form" label-width="100px" style="margin-bottom: 50px">
        <div class="alteration_organization">
          <el-form-item style="text-align: left" label="收票组织">
            <OrganizationSelect
              :model="form"
              allLabel="请选择收票组织"
              :is-add-all="false"
            ></OrganizationSelect>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="takerOrganizationVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlerSelectOrganization"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 批量下载 -->
    <el-dialog
      title="批量下载"
      :visible.sync="downloadInBatchesVisible"
      width="35%"
    >
      <el-form :model="form" style="margin: 10px 0 110px 40px">
        <div class="alteration_organization">
          <el-form-item style="text-align: left" label="选择归类规则"
            ><br />
            <el-radio-group
              style="width: 100%"
              v-model="dlFileParam.groupRule"
              size="small"
            >
              <div style="margin: 15px 0 15px 0">
                <el-row :gutter="20">
                  <el-col :span="8"
                    ><el-radio style="width: 100%" label="NG" border
                      >不需要归类</el-radio
                    ></el-col
                  >
                  <el-col :span="8"
                    ><el-radio style="width: 100%" label="ZT" border
                      >按组织归类</el-radio
                    >
                  </el-col>
                  <el-col :span="8"
                    ><el-radio style="width: 100%" label="PZ" border
                      >按票种归类</el-radio
                    ></el-col
                  >
                </el-row>
              </div>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-radio style="width: 100%" label="SL" border
                    >按税率归类</el-radio
                  >
                </el-col>
                <el-col :span="8"
                  ><el-radio style="width: 100%" label="BN" border
                    >按购方归类</el-radio
                  ></el-col
                >
                <el-col :span="8"
                  ><el-radio style="width: 100%" label="SN" border
                    >按销方归类</el-radio
                  ></el-col
                >
              </el-row>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            style="text-align: left; margin-top: 130px"
            label="选择文件类型"
            ><br />
            <el-select
              multiple
              v-model="dlFileParam.fileTypes"
              collapse-tags
              placeholder="请选择文件类型"
            >
              <el-option
                v-for="item in dlFileTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadInBatchesVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlerDownloadInBatches"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--  导出设置弹窗  -->
    <invoice-export-setting
      :show.sync="exportDrawer"
      @set-column-settings="handleColumnSetting"
    ></invoice-export-setting>
  </div>
  <router-view v-else></router-view>
</template>

<script>
import { DataUtil } from "@/util/dataUtil";
import {
  againPushInvoice,
  buyerInfoAudit,
  deleteInvoiceByIds,
  pushInvoiceByIds,
  deleteInvoiceList,
  downOriginalFile,
  getInvoiceCenterByPage,
  selectInvoice,
  getInvoiceImageListById,
  getInvoiceListImage,
  getInvoiceListsById,
  getPhoneScanningQRCode,
  getRecognizeBatchNo,
  handleExportBatch,
  invoiceExplainUpdate,
  invoiceManualInput,
  updateInvoiceById,
  updateInvoiceList,
  invoiceImport,
  updateInvoiceSettlementStatus,
  batchGeneratePdf,
  batchGeneratePdfPreQuery,
  ticketCollectorOrganization,
  asyncInvoiceFile,
  invoiceDownload,
  generatePdf,
  scannerInvoice,
} from "@/service/invoice-center";
import { reqVerifyInvoice } from "@/service/col/invoice";
import {
  changeMethods,
  dateOption,
  pagingMethods,
  tableStyle,
} from "@/util/mixins";
import RegexLib from "@/assets/js/regex-lib";
import InvoiceDetail from "@/components/invoice/InvoiceDetail";
import InvoiceExportSetting from "@/components/invoice/InvoiceExportSetting";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { INVOICE_IMAGE_TYPE, MANUAL_INVOICE_TYPES } from "@/content/invoice";
import { getCurrency } from "@/service/pay/currency";
import { listTntConfig } from "@/service/system/config";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import InvoiceOpenGoodsList from "@/views/invoice-open/invoice-issue/retail/components/RetailInvoiceOpenGoodsList.vue";
import MerchandiseListDialog from "@/views/invoice-open/invoice-issue/components/MerchandiseListDialog.vue";
import cusMessage from "@/components/message/Message";
import DragScaleImg from "@/components/DragScaleImg";
import InvoiceImport from "@/views/voucher-manage/items/InvoiceImport.vue";
import { parseCategory } from "@/assets/js/invoice-parse-util";
import ColInvoiceImage from "@/views/invoice-center/component/ColInvoiceImage.vue";
export default {
  name: "InvoiceCenterList",
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: {
    InvoiceExportSetting,
    InvoiceDetail,
    ElImageViewer,
    OrganizationSelect,
    InvoiceOpenGoodsList,
    MerchandiseListDialog,
    cusMessage,
    InvoiceImport,
    DragScaleImg,
    ColInvoiceImage,
  },
  data() {
    return {
      sortOrder: "1",
      screenWidth: 400,
      dlFileParam: {
        invoiceIds: [],
        groupRule: "NG",
        fileTypes: ["PDF"], //批量下载文件类型
      },
      dlFileTypes: INVOICE_IMAGE_TYPE, //文件类型枚举

      value: "",
      form: {
        orgId: "",
        name: "",
        region: "",
      },
      invoiceImageVisible: false,
      downloadInBatchesVisible: false,
      takerOrganizationVisible: false,
      TypeOfReceiptList: [
        {
          value: "PHOTOGRAPH",
          label: "拍照收票",
        },
        {
          value: "SCAN",
          label: "扫码",
        },
        {
          value: "GUN",
          label: "扫码枪",
        },
        {
          value: "SCANNER",
          label: "扫描仪",
        },

        {
          value: "PICTURE",
          label: "图片上传",
        },
        {
          value: "IMG",
          label: "图片",
        },
        {
          value: "PDF",
          label: "PDF文件",
        },
        {
          value: "OFD",
          label: "OFD文件",
        },
        {
          value: "XML",
          label: "XML文件",
        },
        {
          value: "IMPORT",
          label: "导入收票",
        },
        {
          value: "EMAIL",
          label: "邮箱",
        },
        {
          value: "SUPPLIER",
          label: "结算收票 - 供应商",
        },
        {
          value: "RETAILER",
          label: "结算收票 - 零售商",
        },
        {
          value: "ISSUE",
          label: "系统开具",
        },
        {
          value: "INCOME",
          label: "进项推送",
        },
        {
          value: "SALES",
          label: "销项推送",
        },
        {
          value: "VERIFY",
          label: "查验推送",
        },
        {
          value: "WECHAT",
          label: "微信卡包",
        },
        {
          value: "FENG_LIAN",
          label: "峰连科技",
        },
        {
          value: "MANUNAL",
          label: "手工录入",
        },
      ],
      IfRemarkList: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      IfDownloadList: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      imgPre: "",
      isShowPhoto: false,
      activeName: "master",
      invoiceImportVisible: false, // 导入收票弹窗
      dialogGoodsListVisible: false,
      currentGoodsIndex: 0,
      goodsList: [],
      isExistListArr: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      isInvoiceSealArr: [
        { value: true, label: "已盖章" },
        { value: false, label: "未盖章" },
      ],

      invoiceId: "",
      tips: "匹配：发票中的购方名称、税号与系统维护的组织信息一致<br>不匹配：发票中的购方名称、税号与系统维护的组织信息不一致<br>",
      columns: {
        invoiceType: {
          label: "发票类型",
          visible: true,
        },
        invoiceCode: {
          label: "发票代码",
          visible: true,
        },
        invoiceNo: {
          label: "发票号码",
          visible: true,
        },
        sumAmount: {
          label: "价税合计",
          visible: true,
        },
        amount: {
          label: "金额",
          visible: true,
        },
        taxAmount: {
          label: "税额",
          visible: true,
        },
        buyerName: {
          label: "购方名称",
          visible: true,
        },
        buyerInfoAudit: {
          label: "购方信息稽核",
          visible: true,
        },
        sellerName: {
          label: "销方名称",
          visible: true,
        },
        invoiceTime: {
          label: "开票日期",
          visible: true,
        },
        receiveTime: {
          label: "收票时间",
          visible: true,
        },
        isUseVerifyPlatform: {
          label: "是否启用发票查验平台",
          visible: true,
        },
        verifyStatus: {
          label: "发票查验平台查验状态",
          visible: true,
        },
        isUseIncomeVerifyPlatform: {
          label: "是否启用综合服务平台",
          visible: true,
        },
        incomeVerifyStatus: {
          label: "综合服务平台查验状态",
          visible: true,
        },
        remark: {
          label: "备注",
          visible: true,
        },

        returnSuccess: {
          label: "回传成功",
          visible: false,
        },

        invoiceStatus: {
          label: "发票状态",
          visible: true,
        },
        sealStatus: {
          label: "验章状态",
          visible: true,
        },
        settlementStatus: {
          label: "结算状态",
          visible: true,
        },
        auditStatus: {
          label: "稽核状态",
          visible: true,
        },
        deductionStatus: {
          label: "抵扣状态",
          visible: true,
        },
        isExistList: {
          label: "是否有清单",
          visible: true,
        },
        batchNo: {
          label: "识别批次",
          visible: true,
        },
        imageType: {
          label: "影像类型",
          visible: true,
        },
        orgName: {
          label: "收票组织",
          visible: true,
        },
        creatorName: {
          label: "上传者",
          visible: true,
        },
        invoiceExplain: {
          label: "发票说明",
          visible: true,
        },

        ifDownload: {
          label: "是否下载",
          visible: true,
        },
        sender: {
          label: "发件人",
          visible: true,
        },
        senderEmail: {
          label: "发件邮箱",
          visible: true,
        },
        receiveTimeS: {
          label: "收件时间",
          visible: true,
        },
        channel: {
          label: "收票类型",
          visible: true,
        },
        eInvoiceNo: {
          label: "数电发票号码",
          visible: true,
        },
        owneriod: {
          label: "所属周期",
          visible: true,
        },
        checkedTime: {
          label: "勾选日期",
          visible: true,
        },
        matchStatus: {
          label: "匹配状态",
          visible: true,
        },
        receiveFrom: {
          label: "发票来源",
          visible: true,
        },
        operate: {
          label: "操作",
          visible: true,
        },
      },
      receiveFromList: [
        { value: "", label: "全部" },
        { value: 1, label: "销项发票" },
        { value: 2, label: "进项发票" },
      ],
      isIndeterminate: true,
      checkAll: false,
      deductionStatusArr: [],
      buyerInfoAuditArr: [],
      auditStatusArr: [],
      returnSuccessEnums: [],
      isCollect: true,
      search: {
        invoiceLabel: "ordinary",
        invoiceCode: undefined,
        invoiceNo: undefined,
        batchNo: undefined,
        verifyStatus: undefined,
        incomeVerifyStatus: undefined,
        invoiceTime: undefined,
        receiptDate: undefined,
        invoiceTypeList: undefined,
        sealStatus: undefined,
        settlementStatus: undefined,
        sellerName: undefined,
        buyerName: undefined,
        buyerInfoAudit: undefined,
        invoiceIds: [],
        columns: [],
        exportItems: false,
        isVatInvoice: true,
        page: 1,
        size: 15,

        ifDownload: undefined,
        ifHasRemark: undefined,
        remark: undefined,
        sender: undefined,
        senderEmail: undefined,

        channel: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,

        isCheck: undefined,
        deductionStatus: undefined,
        receiveFrom: undefined,
        matchStatus: undefined,
      },
      total: 0,
      tableData: [],
      invoiceStatus: [],
      invoiceStatusList: [
        { label: "蓝票", value: "0" },
        { label: "红票", value: "1" },
        { label: "全额冲红", value: "2" },
        { label: "作废", value: "3" },
        { label: "部分冲红", value: "4" },
      ],
      manualVisible: false,
      manualInputContent: {
        invoiceType: "SpecInvoice",
        invoiceCode: "",
        invoiceNo: "",
        invoiceTime: "",
        amount: "",
        checkCode: "",
        verifyCode: "",
        batchNo: "",
        source: "CENTER",
        channel: "MANUAL",
        invoiceLabel: "ordinary",
        orgId: this.$store.state.user.currUser.orgId,
      },
      phoneVisible: false,
      tableLoading: false,
      verifyStatusSet: [],
      invoiceTypeSet: [],
      invoiceTypeAllSet: [],
      manualInvoiceTypes: [],
      sealStatus: [],
      qrCodeImg: "",
      /* 票据详情数据区 */
      drawer: false,
      loading: false,
      invoice: {},
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      /* 票据清单删除数据区 */
      invoiceListDelete: {},
      InvoiceListDeleteVisible: false,
      exportConfirmation: false,
      exportQuantity: 0,
      /* 票据修改数据区 */
      updateVisible: false,
      invoiceUpdate: {
        id: "",
        invoiceType: "",
        invoiceCode: "",
        invoiceNo: "",
        amount: "",
        checkCode: "",
        verifyCode: "",
        invoiceTime: "",
        buyerTaxNo: "",
        invoiceLabel: "ordinary",
        invoiceStatus: "",
        eInvoiceNo: "",
      },
      /* 编辑发票说明 */
      editVisibleExplain: false,
      explainWarning: false,
      invoiceExplain: "",
      invoiceExplainUpdateIds: [],
      /* 批量变更结算状态区 */
      isBatch: false,
      settlementStatus: [],
      settlementStatusUpdateTitle: "修改结算状态",
      batchGeneratePdfTitle: "生成影像确认",
      settlementStatusUpdateContent: "",
      batchGeneratePdfContent: "",
      settlementStatusUpdateIds: [],
      batchGeneratePdfIds: [],
      settlementStatusUpdateVisible: false,
      batchGeneratePdfVisible: false,
      needToGeneratePdfCount: 0,
      currency: {
        currencyCash: 0,
        verifySpend: 0,
        verifySwitch: "N",
        recognizeSpend: 0,
        recognizeSwitch: "Y",
      },
      editableTabsValue: "",
      generatePdfData: {},
      rules: {
        orgId: [
          { required: true, message: "请选择收票组织", trigger: "change" },
        ],
        invoiceType: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        invoiceCode: [
          { required: true, message: "请输入发票代码", trigger: "blur" },
          {
            min: 10,
            max: 12,
            message: "请输入10位或12位的发票代码",
            trigger: "blur",
          },
          { pattern: RegexLib.InvoiceCode, message: "请输入正确的发票代码" },
        ],
        invoiceNo: [
          { required: true, message: "请输入发票号码", trigger: "blur" },
          { min: 8, max: 8, message: "请输入8位发票号码", trigger: "blur" },
          { pattern: RegexLib.InvoiceNo, message: "请输入正确的发票号码" },
        ],
        eInvoiceNo: [
          { required: true, message: "请输入数电发票号码", trigger: "blur" },
          {
            min: 20,
            max: 20,
            message: "请输入20位数电发票号码",
            trigger: "blur",
          },
          { pattern: RegexLib.InvoiceNo, message: "请输入正确的数电发票号码" },
        ],
        invoiceNoNumber: [
          { required: true, message: "请输入数电发票号码", trigger: "blur" },
          {
            min: 20,
            max: 20,
            message: "请输入20位数电发票号码",
            trigger: "blur",
          },
          { pattern: RegexLib.InvoiceNo, message: "请输入正确的数电发票号码" },
        ],

        invoiceTime: [
          { required: true, message: "请选择开票时间", trigger: "blur" },
        ],
        amount: [
          { required: true, message: "请输入发票金额", trigger: "blur" },
          {
            pattern: RegexLib.Amount,
            message: "请输入正确的金额",
          },
        ],
        verifyCode: [
          { required: true, message: "请输入校验码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入校验码后六位", trigger: "blur" },
          {
            pattern: RegexLib.InvoiceVerifyCode,
            message: "请输入正确的校验码",
          },
        ],
        pageSize: [
          { required: true, message: "请输入总页数", trigger: "blur" },
          { min: 1, message: "请输入至少1位总页数", trigger: "blur" },
          { pattern: RegexLib.PageSize, message: "请输入正确的总页数" },
        ],
        pageIndex: [
          { required: true, message: "请输入当前页数", trigger: "blur" },
          { min: 1, message: "请输入至少1位当前页数", trigger: "blur" },
          { pattern: RegexLib.PageIndex, message: "请输入正确的当前页数" },
        ],
      },
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },
      /* 票据删除数据区 */
      auditBatchIds: [],
      auditWindow: false,
      selections: [],
      deleteSelectionsClass: "batch-deleted",
      selectExpended: false,
      invoiceBatchDeleteVisible: false,
      invoiceBatchPushVisible: false,
      downLoading: false,
      exportLoading: false,
      invoiceListVisible: false,
      innerVisible: false,
      invoiceListData: [],
      invoiceListPageSize: 0,
      invoiceListUnmatched: [],
      invoiceListUpdateData: {},
      /* 导出设置弹窗 */
      exportDrawer: false,
      exportColumns: [],
      colInvoiceId: "",
    };
  },
  watch: {
    sortOrder: {
      handler(val) {
        console.log(val);
        if (val == "1") {
          this.invoiceListData = this.invoiceListData.sort((a, b) => {
            return a.id - b.id;
          });
        } else {
          this.invoiceListData = this.invoiceListData.sort((a, b) => {
            return a.pageIndex - b.pageIndex;
          });
        }
      },
    },
    updateVisible(val) {
      if (!val) {
        this.imgPre = "";
      }
    },
    "$route.path": {
      handler() {
        if (this.$route.path == "/collect/list") {
          this.isCollect = true;
        } else {
          this.isCollect = false;
        }
        this.handleQuery();
      },
      immediate: true,
    },
    "$store.state.isCollapse": {
      handler() {
        this.deleteSelectionsClass = this.$store.state.isCollapse
          ? "batch-deleted-hidden"
          : "batch-deleted";
      },
      immediate: true,
    },
  },
  computed: {
    isInvoiceList() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType === "VatInvoiceList";
    },
    hasAmount() {
      return (value) => {
        let invoiceType = value.invoiceType;
        return invoiceType == "GenerInvoice" ||
          invoiceType == "EleInvoice" ||
          invoiceType == "EleSpecInvoice" ||
          invoiceType == "EleTollInvoice" ||
          invoiceType == "VolInvoice"
          ? false
          : true;
      };
    },
    hasCheckCode() {
      return (value) => {
        return (
          [
            "SpecInvoice",
            "VehInvoice",
            "FullEleSpecInvoice",
            "FullEleGenerInvoice",
            "ElePlaneInvoice",
            "EleTrainInvoice",
          ].indexOf(value.invoiceType) == -1
        );
      };
    },
  },
  created() {
    this.invoiceTypeSet = this.$store.state.enums.InvoiceType;
    this.manualInvoiceTypes = this.$store.state.enums.InvoiceType;
    this.handleInitDictionaries();
    this.verifyStatusSet = [
      { label: "全部", value: undefined },
      ...this.$store.state.enums.InvoiceVerifyStatus,
    ];
    this.invoiceTypeAllSet = [
      { label: "全部", value: undefined },
      ...this.invoiceTypeSet,
    ];
    this.invoiceTypeAllSet.forEach((item) => {
      item.disabled = false;
    });
    this.sealStatus = [
      { label: "全部", value: undefined },
      ...this.$store.state.enums.InvoiceSealStatus,
    ];
    this.handleTableData();
  },
  methods: {
    handlerRank() {
      this.invoiceListVisible = true;
      this.handleInvoiceLists();
    },
    invoiceTypeSetChange() {
      if (this.search.invoiceTypeList.length == 0) {
        this.invoiceTypeAllSet.forEach((item) => {
          item.disabled = false;
        });
      }
      this.search.invoiceTypeList.forEach((item) => {
        if (item == undefined) {
          this.invoiceTypeAllSet.forEach((item) => {
            item.disabled = true;
          });
          this.invoiceTypeAllSet[0].disabled = false;
        } else {
          this.invoiceTypeAllSet[0].disabled = true;
        }
      });
    },
    // 更改收票组织
    handelTakerOrganizationVisible() {
      if (this.selections.length == 0) {
        this.toast("请选择要更改的收票组织！", "warning");
        return;
      }
      this.takerOrganizationVisible = true;
    },
    // 更改收票组织确定
    handlerSelectOrganization() {
      this.ticketCollectorOrganizationSync();
      this.takerOrganizationVisible = false;
    },
    async ticketCollectorOrganizationSync() {
      let ids = this.selections.map((item) => item.id);
      let param = {
        invoiceIds: ids,
        orgId: this.form.orgId,
      };
      let res = await ticketCollectorOrganization(param);
      if (res.success) {
        this.toast("收票组织修改成功！", "success", () => {
          this.invoiceBatchDeleteVisible = false;
          this.handleQuery();
          this.cancelDeleteBatch();
        });
      } else {
        this.toast("收票组织修改失败！", "error");
      }
    },

    // 根据发票代码推算发票类型
    getInvoiceTypeFromInvoiceCode() {
      this.manualInputContent.invoiceType = parseCategory(
        this.manualInputContent.invoiceCode
      );
    },
    // 表格项被焦点
    handleFocusTd(val) {
      this.currentGoodsIndex = val.index;
      // 商品明细已经填写了 "名称 & 金额 & 税率"
      if (val.merchandiseName && val.amount && val.taxRate != null) {
        this.hadTdFocus = val.focus;
        this.discountStatus = val.itemProperty > 0 ? true : false;
      } else {
        this.hadTdFocus = false;
      }
    },
    // 新增商品信息弹窗
    handleInsertMerchandise(row) {
      this.merchandise.index = row.index;
      this.merchandise.merchandiseName = row.merchandiseName;
      this.editVisible = !!this.merchandise.merchandiseName;
    },
    // 显示折扣相关弹窗
    handleDiscount() {
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      this.discountForm = { ...this.discountForm, ...goods };
      this.deleteGoods = goods.itemProperty == "2";
      this.discountStatus
        ? (this.deletedDiscountVisible = true)
        : (this.insertDiscountVisible = true);
    },
    handReloadCustomerList() {
      this.invoiceImportVisible = false;
      this.handleGetInvoiceData();
    },
    // 商品标识符
    changeIndex(value) {
      this.goodsIndex += value;
    },
    // 商品选中
    handleCurrentGoods(row) {
      // console.log(row, '------handleCurrentGoods------');
      let goods = this.goodsList.find((i) => i.index == this.currentGoodsIndex);
      if (!goods) return;

      if (row.specification) {
        goods.specification = row.specification;
      }
      if (row.unit) {
        goods.unit = row.unit;
      }
      // 免税-不征税明细
      if (
        row.taxPreferentialContent == "免税" ||
        row.taxPreferentialContent == "不征税"
      ) {
        goods["taxRateModel"] = row.taxPreferentialContent;
        goods["taxRate"] = 0;
      } else {
        goods["taxRate"] = Number(row.taxRate);
        goods["taxRateModel"] = Number(row.taxRate);
      }
      goods.price = row.price == 0 ? null : row.price;
      goods.uniqueCode = row.uniqueCode;
      goods.taxClassCode = row.taxClassCode;

      // 显示值
      if (!row.merchandiseName && row.untaxMerchandiseName) {
        row.merchandiseName = row.untaxMerchandiseName;
      }
      goods.merchandiseName = /^\*/.test(row.merchandiseName)
        ? row.merchandiseName
        : `*${row.taxClassName}*${row.merchandiseName}`;
      goods.notFirst = true;
      goods.taxClassName = row.taxClassName;
      goods.taxVersionNo = row.taxVersionNo;
      goods.isTaxPreferential = row.isTaxPreferential;
      goods.taxPreferentialContent = row.taxPreferentialContent;
      goods.priceVariable = row.priceVariable;
      goods.visible = false;

      this.$refs.invoiceGoodsList.handleKeyupGoodsPrice(goods);
      this.dialogGoodsListVisible = false;
      let inputDom = document.getElementById("input-" + row.index);
      inputDom ? inputDom.blur() : null;

      // 焦点移动
      setTimeout(() => this.$refs.invoiceGoodsList.focusSpec(goods.index), 0);
    },
    // 展开商品选择弹窗
    handleShowGoods(goods) {
      this.dialogGoodsListVisible = true;
      this.currentGoodsIndex = goods.index;
    },
    // 输入货物清单通知
    handleMerchandiseNameBlur(row, merchandises) {
      //  失去焦点行下标
      this.currentGoodsIndex = row.index;
      if (!row.taxClassCode) {
        this.handleInsertMerchandise(DataUtil.clone(row));
        return;
      }
      this.handleCurrentGoods(row);
    },
    // 发票价税补全
    handleReviewInvoice(invoicePrice) {
      // this.invoicePrice = invoicePrice;
    },
    handleDeleteItems(index) {
      // this.deleteIndex = index;
    },
    async handleTableData() {
      const { success, data } = await this.getColumnSetting("SPZX");
      if (success && data != null && data != "") {
        this.columns = Object.assign(this.columns, JSON.parse(data));
      }
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      this.upInsertColumnSetting("SPZX", JSON.stringify(this.columns));
    },
    //列设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      this.upInsertColumnSetting("SPZX", JSON.stringify(this.columns));
    },
    //菜单按钮
    handleCommand(command) {
      if ("editorExplainBatch" == command) {
        this.handleEditorExplainBatch();
      }
      if ("settlementStatusUpdateBatch" == command) {
        this.handleSettlementStatusUpdateBatch();
      }
      if ("batchGeneratePdfBatch" == command) {
        this.handleBatchGeneratePdfBatch();
      }
      if ("auditBatch" == command) {
        this.handleAuditBatch();
      }
      if ("changeDept" == command) {
        this.handelTakerOrganizationVisible();
      }
      if ("pushInvoice" == command) {
        this.handleInvoicePushBatch();
      }
    },
    //菜单按钮列表内
    handleCommandLine(command) {
      switch (command.command) {
        case "againPush":
          this.handleAgainPushInvoice(command.scope.row);
          break;
        case "settlementStatusEdit":
          this.handleSettlementStatus(command.scope.row);
          break;
        case "invoiceExplainEdit":
          this.handleInvoiceExplain(command.scope.row);
          break;
        case "generate":
          this.handlerGenerate(command.scope.row);
          break;
        case "invoiceImage":
          this.handlePreviewImage(command.scope.row);
          break;
      }
    },
    beforeHandleCommandLine(scope, command) {
      return {
        scope: scope,
        command: command,
      };
    },
    // 浏览发票影像
    handlePreviewImage(row) {
      this.colInvoiceId = row.id;
      this.invoiceImageVisible = true;
    },

    /* 批量稽核 */
    handleAuditBatch() {
      // 选中数量检查
      if (this.selections.length === 0) {
        this.toast("请选择需要稽核的数据！", "warning");
        return;
      }
      this.auditBatchIds = this.selections.map((item) => item.id);
      //调用接口
      buyerInfoAudit(this.auditBatchIds).then((res) => {
        if (res.success) {
          if (res.data) {
            this.handleQuery();
            this.toast("操作成功！", "success");
          }
        } else {
          this.InvoiceListDeleteVisible = false;
          this.toast(res.message, "error");
        }
      });
    },
    // 表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "共:";
          return;
        }
        if (index === 1) {
          sums[index] = this.selections.length + "张";
          return;
        }
        const values = this.selections.map((item) =>
          Number(item[column.property])
        );
        if (
          !values.every((value) => isNaN(value)) &&
          (column.label === "金额" ||
            column.label === "税额" ||
            column.label === "价税合计")
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          if (column.label === "金额") {
            sums[index] = "金额：" + sums[index];
          } else if (column.label === "税额") {
            sums[index] = "税额：" + sums[index];
          } else if (column.label === "价税合计") {
            sums[index] = "价税合计：" + sums[index];
          }
        } else {
          if (column.label === "金额") {
            sums[index] = "金额：" + 0;
          } else if (column.label === "税额") {
            sums[index] = "税额：" + 0;
          } else if (column.label === "价税合计") {
            sums[index] = "价税合计：" + 0;
          }
        }
      });
      return sums;
    },
    // 去重过滤
    uniqueBy(arr, fn) {
      const seen = {};
      return arr.filter((el) => {
        const e = fn(el);
        return !(e in seen) && (seen[e] = 1);
      });
    },
    /* 收票中心数据列表 */
    async handleGetInvoiceData() {
      this.tableLoading = true;
      const { success, data } = await getInvoiceCenterByPage(this.search);
      if (!success) {
        this.toast("发票中心数据获取失败！", "error");
        this.tableLoading = false;
        return;
      }
      // 查询结果处理
      this.total = data.total;
      this.tableData = data.records.map((item) => {
        this.$set(item, "loading", false);
        return item;
      });
      this.tableLoading = false;
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "发票类型":
          return this.handleValueToLabel("InvoiceType", value);
        case "金额":
        case "税额":
        case "价税合计":
          if (value == null) return "￥" + "0";
          return "￥" + value;
        case "影像类型":
          return this.handleValueToLabel("InvoiceImageType", value);
        case "识别批次":
          if (value) return value;
          return "--";
        case "备注":
          if (value) return value;
          return "--";
        case "收票组织":
        case "发件邮箱":
        case "上传者":
          if (value) return value;
          return "--";
        case "发件人":
          if (value) return value;
          return "--";
        case "发件人邮箱":
          if (value) return value;
          return "--";
        case "数电发票号码":
          if (value) return value;
          return "--";
        case "收件时间":
          if (value) return value;
          return "--";
        case "收票类型":
          if (value != null && value != "") {
            return this.getDictLabel(this.TypeOfReceiptList, value);
          }
          return "--";
        case "匹配状态":
          if (value == "0") {
            return "未匹配";
          } else if (value == "1") {
            return "已匹配";
          } else if (value == "2") {
            return "匹配失败";
          }
          return "--";
        case "发票来源":
          if (value === 1) {
            return "销项发票";
          } else if (value === 2) {
            return "进项发票";
          } else {
            return "--";
          }
        case "开票日期":
          if (value != undefined) {
            return this.$moment(value).format("YYYY-MM-DD");
          }
          return "--";
        case "结算状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.settlementStatus, value);
          }
          return "--";
        case "稽核状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.auditStatusArr, value);
          }
          return "--";
        case "抵扣状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.deductionStatusArr, value);
          }
          return "--";
        case "发票说明":
          if (value) return value;
          return "--";
        case "发票状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.invoiceStatusList, value);
          }
          return "--";
        case "销方名称":
          if (value != null && value != "") {
            return value;
          }
          return "--";
        case "购方名称":
          if (value != null && value != "") {
            return value;
          }
          return "--";
        case "购方信息稽核":
          if (value != null && value != "") {
            return this.getDictLabel(this.buyerInfoAuditArr, value);
          }
          return "--";
        default:
          return value;
      }
    },
    formatReturnSuccessEnums(val) {
      if (val != null && val != "") {
        return this.getDictLabel(this.returnSuccessEnums, val);
      }
      return "--";
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      this.batchGetDicts({
        types: [
          "invoice_status",
          "settlement_status",
          "audit_status",
          "deduction_status",
          "buyer_info_audit",
          "sys_normal_yes_no",
        ],
      }).then(({ success, data }) => {
        if (success) {
          // 发票税控状态
          this.invoiceStatus = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["invoice_status"],
          ];

          // 发票结算状态
          this.settlementStatus = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["settlement_status"],
          ];

          // 发票稽核状态
          this.auditStatusArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["audit_status"],
          ];

          // 发票抵扣状态
          this.deductionStatusArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["deduction_status"],
          ];

          // 购方信息稽核
          this.buyerInfoAuditArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["buyer_info_audit"],
          ];

          // 回传成功状态
          this.returnSuccessEnums = [...data["sys_normal_yes_no"]];
        }
      });
    },
    /* 展开筛选列表 */
    handleListShow() {
      this.selectExpended = true;
    },
    /* 收起筛选列表 */
    handleListClose() {
      this.selectExpended = false;
    },
    /* 重置查询 */
    handleReset() {
      this.search = {
        invoiceLabel: "ordinary",
        invoiceCode: undefined,
        invoiceNo: undefined,
        batchNo: undefined,
        verifyStatus: undefined,
        invoiceTime: undefined,
        invoiceType: undefined,
        sealStatus: undefined,
        settlementStatus: undefined,
        isVatInvoice: true,
        page: 1,
        size: 15,
        ifDownload: undefined,
        ifHasRemark: undefined,
        remark: undefined,
        sender: undefined,
        senderEmail: undefined,

        channel: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,

        isCheck: undefined,
        deductionStatus: undefined,
        receiveFrom: undefined,
        matchStatus: undefined,
      };
      this.invoiceTypeAllSet.forEach((item) => {
        item.disabled = false;
      });
    },
    /* 条件查询 */
    handleQuery() {
      this.handleGetInvoiceData();
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
      this.selections.forEach((item) => {
        item.invoiceId = item.id;
      });
    },
    /* 手工导入取消 */
    handleManualInputCancel(manualInput) {
      this.manualVisible = false;
      this.$refs[manualInput].resetFields();
    },
    /* 手工导入确认 */
    handleManualInputConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getRecognizeBatchNo().then((batchNoRes) => {
            if (batchNoRes.success) {
              this.manualInputContent.batchNo = batchNoRes.data;
              invoiceManualInput(this.manualInputContent).then((res) => {
                if (res.success) {
                  this.handleGetInvoiceData();
                  this.manualVisible = false;
                  this.toast("操作成功", "success");
                  this.getCurrency();
                } else {
                  this.toast(res.message, "error");
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    /* 扫描枪收票 */
    async handleScanningGun() {
      await this.checkCurrencyCash(() => {
        this.isCollect = false;
        this.$router.push({ path: "/collect/list/scanning/gun" });
      }, false);
    },
    /* 扫描收票 */
    async handleScannerScan() {
      await this.checkCurrencyCash(() => {
        this.isCollect = false;
        this.$router.push({ path: "/collect/list/scanner" });
      }, true);
    },
    /* 手工导入收票 */
    async handleManualInput() {
      await this.checkCurrencyCash(() => {
        this.manualVisible = true;
      }, false);
    },
    /* 本地图片收票 */
    async handleLocalPictures() {
      await this.checkCurrencyCash(() => {
        this.isCollect = false;
        this.$router.push({ path: "/collect/list/upload" });
      }, true);
    },
    /* 检查喵点余量 */
    async checkCurrencyCash(passed, isRecognize) {
      await this.getCurrencyInformation();

      // 预计花费点数
      let expectSpend = 0;
      // 识别消耗点数
      if (isRecognize && this.currency.recognizeSwitch == "Y") {
        expectSpend += this.currency.recognizeSpend;
      }
      // 查验消耗点数
      if (this.currency.verifySwitch == "Y") {
        expectSpend += this.currency.verifySpend;
      }
      // 判断剩余点数
      if (this.currency.currencyCash >= expectSpend) {
        if (passed) passed();
      } else {
        this.toast("喵点余量不足，请充值后使用！", "warning");
      }
    },
    /* 发票详情 */
    handleViewDetails(invoice) {
      this.drawer = !this.drawer;
      this.loading = true;
      selectInvoice(invoice.id).then((res) => {
        if (res.success) {
          this.loading = false;
          let details = res.data.items;
          if (details) {
            details.forEach(
              (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
            );
            // 统计信息
            let total = {
              merchandiseName: "合计",
              amount: invoice.amountString
                ? invoice.amountString
                : invoice.amount,
              taxAmount: invoice.taxAmountString
                ? invoice.taxAmountString
                : invoice.taxAmount,
            };
            details.push(total);
            let sum = Number(invoice.sumAmount).toFixed(2);
            let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              merchandiseName: "价税合计（大写）",
              specification: sumBig + "  （小写）￥" + sum,
            };
            details.push(priceTax);
          }
          let invoiceData = res.data;
          invoiceData.items = details;
          this.invoice = invoiceData;
        }
      });
    },
    // 之前影像
    handleInvoiceImg(invoice, isLook = true) {
      invoice.loading = true;
      getInvoiceImageListById(invoice.id).then((res) => {
        if (res.success) {
          if (isLook) {
            this.invoiceImageViewer = true;
          }
          this.imgPre = res.data[0];
          this.invoiceImages = res.data;
        }
        invoice.loading = false;
      });
    },
    /* 发票信息重新推送 */
    async handleAgainPushInvoice(invoice) {
      const { success, message } = await againPushInvoice(invoice.id);
      if (success) {
        this.toast("发票重新推送成功！", "success");
      } else {
        this.toast(message, "error");
      }
    },
    async handlerGenerate(invoice) {
      let res = await selectInvoice(invoice.id);
      if (res.success) {
        let resPdf = await generatePdf(res.data);
        if (resPdf.success) {
          this.toast("pdf文件生成成功！", "success");
        }
      }
    },
    /* 发票清单删除弹窗 */
    handleInvoiceListDeletePopup(invoice) {
      this.invoiceListVisible = false;
      this.InvoiceListDeleteVisible = true;
      this.invoiceId = invoice.id;
    },
    /* 发票清单删除取消 */
    cancelDeleteInvoiceList() {
      this.InvoiceListDeleteVisible = false;
      this.invoiceListDelete = {};
      this.invoiceId = "";
    },
    /* 发票清单删除确认 */
    deleteInvoiceListConfirm() {
      //发送请求
      deleteInvoiceList(this.invoiceId).then((res) => {
        if (res.success) {
          if (res.data) {
            this.InvoiceListDeleteVisible = false;
            this.handleInvoiceLists(this.invoiceListDelete);
            this.handleQuery();
            this.toast("发票清单删除成功！", "success");
          }
        } else {
          this.InvoiceListDeleteVisible = false;
          this.toast(res.message, "error");
        }
      });

      this.InvoiceListDeleteVisible = false;
    },
    /* 发票清单编辑弹窗 */
    handleInvoiceListUpdatePopup(invoice) {
      this.innerVisible = true;
      this.invoiceListUpdateData = {
        id: invoice.id,
        invoiceId: invoice.invoiceId,
        invoiceType: "VatInvoiceList",
        invoiceCode: invoice.invoiceCode,
        invoiceNo: invoice.invoiceNo,
        pageSize: invoice.pageSize,
        pageIndex: invoice.pageIndex,
      };
    },
    /* 发票清单编辑确认 */
    async handleInvoiceListUpdateConfirm(invoiceListUpdate) {
      this.$refs[invoiceListUpdate].validate((valid) => {
        if (valid) {
          updateInvoiceList(this.invoiceListUpdateData).then((res) => {
            if (res.success) {
              if (res.data) {
                getInvoiceListsById(this.invoiceListUpdateData.invoiceId).then(
                  (res) => {
                    if (res.success) {
                      this.invoiceListData = res.data;
                      // 未匹配数组
                      this.invoiceListUnmatched = [];
                      const pageIndexes = this.invoiceListData.map(
                        (list) => list.pageIndex
                      );
                      for (let i = 1; i <= this.invoiceListPageSize; i++) {
                        if (!pageIndexes.includes(i.toString())) {
                          this.invoiceListUnmatched.push(i);
                        }
                      }
                    }
                  }
                );
                this.innerVisible = false;
                this.toast("发票清单编辑成功！", "success");
              }
            } else {
              this.toast(res.message, "error");
            }
          });
        }
      });
    },
    /* 发票清单影像取消 */
    handleInvoiceListUpdateCancel(invoiceListUpdate) {
      this.innerVisible = false;
      this.invoiceListUpdateData = {};
      this.$refs[invoiceListUpdate].resetFields();
    },
    /* 发票清单影像 */
    async handleInvoiceListImage(invoice, isLook = true) {
      const { success, data } = await getInvoiceListImage(invoice.id);
      if (success) {
        this.invoiceImageViewer = true;
        this.invoiceImages.push(data);
      } else {
        this.toast("发票清单影像获取失败!", "error");
      }
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },
    /* 发票修改 */
    handleInvoiceUpdate(invoice) {
      this.updateVisible = true;
      selectInvoice(invoice.id).then((res) => {
        if (res.success) {
          let details = res.data.details;
          if (details) {
            this.goodsList = details.map((item) => {
              return {
                ...item,
                merchandiseName: item.goodName,
                specification: item.general,
                unit: item.specifications,
                quantity: item.goodNum,
                price: item.goodPrice,
                sumAmount: item.amount,
                taxRateModel: item.taxRate,
              };
            });
          }
        }
      });
      this.invoiceUpdate = {
        ...invoice,
        id: invoice.id,
        invoiceType: invoice.invoiceType,
        invoiceCode: invoice.invoiceCode,
        invoiceNo: invoice.invoiceNo,
        amount: invoice.amount,
        checkCode: invoice.checkCode,
        verifyCode:
          invoice.verifyCode ||
          invoice.checkCode.substring(invoice.checkCode.length - 6),
        invoiceTime: invoice.invoiceTime,
        buyerTaxNo: invoice.buyerTaxNo,
        invoiceStatus: invoice.invoiceStatus,
      };
      // this.handleInvoiceImage(invoice, false);
    },
    /* 发票修改确认 */
    handleInvoiceUpdateConfirm(invoiceUpdate) {
      this.goodsList = this.goodsList.map((item) => {
        return {
          ...item,
          goodName: item.merchandiseName,
          general: item.specification,
          specifications: item.unit,
          goodNum: item.quantity,
          goodPrice: item.price,
          amount: item.sumAmount,
          detailAmount: item.sumAmount,
          taxRateModel: item.taxRateModel,
        };
      });

      const tmpParams = {
        ...this.invoiceUpdate,
        details: this.goodsList,
        uniqueCode: `V-${this.invoiceUpdate.invoiceCode}-${this.invoiceUpdate.invoiceNo}`,
      };

      this.$refs[invoiceUpdate].validate((valid) => {
        if (valid) {
          updateInvoiceById(tmpParams).then((res) => {
            if (res.success) {
              this.updateVisible = false;
              this.invoiceUpdate = {};
              this.handleGetInvoiceData();
              this.toast("修改成功", "success");
            }
          });
        } else {
          return false;
        }
      });
    },
    /* 发票修改取消 */
    handleInvoiceUpdateCancel(invoiceUpdate) {
      this.$refs[invoiceUpdate].resetFields();
      this.updateVisible = false;
      this.invoiceUpdate = {};
    },
    /* 结算状态修改 */
    handleSettlementStatus(invoice) {
      this.isBatch = false;
      this.settlementStatusUpdateVisible = true;
      this.settlementStatusUpdateTitle = "修改结算状态";
      const currentStatus = this.getDictLabel(
        this.settlementStatus,
        invoice.settlementStatus
      );
      const updateStatus = currentStatus == "未结算" ? "已结算" : "未结算";
      this.settlementStatusUpdateContent =
        "当前发票结算状态是" +
        `<span style="color:#F5222D;">"` +
        currentStatus +
        `"</span>` +
        "，是否将当前结算状态更新为" +
        `<span style="color:#F5222D;">"` +
        updateStatus +
        `"</span>` +
        "? ";
      this.selections.push(invoice);
    },
    /* 结算状态批量修改 */
    handleSettlementStatusUpdateBatch() {
      // 选中数量检查
      if (this.selections.length === 0) {
        this.toast("请选择需要修改结算状态的数据！", "warning");
        return;
      }
      // 选中数据检查
      const status = this.selections.map((item) => item.settlementStatus);
      if ([...new Set(status)].length > 1) {
        this.toast("只对同一结算状态的发票进行批量操作！", "warning");
        return;
      }
      // 数据处理
      this.isBatch = true;
      this.settlementStatusUpdateVisible = true;
      this.settlementStatusUpdateTitle = "批量修改结算状态";
      const currentStatus = this.getDictLabel(
        this.settlementStatus,
        this.selections[0].settlementStatus
      );
      const updateStatus = currentStatus == "未结算" ? "已结算" : "未结算";
      this.settlementStatusUpdateContent =
        "当前所勾选发票发票结算状态是" +
        `<span style="color:#F5222D;">"` +
        currentStatus +
        `"</span>` +
        "，是否将当前勾选发票的结算状态更新为" +
        `<span style="color:#F5222D;">"` +
        updateStatus +
        `"</span>` +
        "? ";
    },
    /*批量生成PDF文件*/
    handleBatchGeneratePdfBatch() {
      // 选中数量检查
      if (this.selections.length === 0) {
        this.toast("请选择需要生成PDF文件的数据！", "warning");
        return;
      }
      //调接口查前置数据
      const param = {
        invoiceIds: this.selections.map((item) => item.id),
      };
      batchGeneratePdfPreQuery(param).then((res) => {
        const totalInvoiceCount = res.data.totalInvoiceCount;
        const invoiceHadPdfCount = res.data.invoiceHadPdfCount;
        const failToSatisfyConditionCount =
          res.data.failToSatisfyConditionCount;
        this.needToGeneratePdfCount = res.data.needToGeneratePdfCount;

        // 数据处理
        this.batchGeneratePdfVisible = true;
        this.batchGeneratePdfTitle = "生成影像确认";

        this.batchGeneratePdfContent =
          `<div style="font-size:16px;">` +
          "确定将所选发票生成 PDF影像吗？" +
          `</div>` +
          "<br/>" +
          `<div style="font-size:14px;">` +
          "共选择发票张数：" +
          "<b>" +
          totalInvoiceCount +
          "</b>" +
          "<br/>" +
          "已包含影像张数：" +
          "<b>" +
          invoiceHadPdfCount +
          "</b>" +
          "<br/>" +
          "不满足条件张数：" +
          "<b>" +
          failToSatisfyConditionCount +
          "</b>" +
          "<br/>" +
          "需生成影像张数：" +
          "<b>" +
          this.needToGeneratePdfCount +
          `</div>` +
          "<br/>" +
          `<div style="font-size:12px;color:red;">` +
          "提示：系统当前仅支持数电类型（电子发票（普通发票）、电子发票（增值税专用发票））发票生成PDF影像，自动过滤掉不满足生成影像条件的数据，且已经生成PDF影像的发票不会重复生成。" +
          `</div>`;
      });
    },
    /* 结算状态更新取消 */
    handleSettlementStatusUpdateCancel() {
      this.settlementStatusUpdateVisible = false;
      this.settlementStatusUpdateIds = "";
    },
    /* 批量生成PDF文件取消 */
    handleBatchGeneratePdfCancel() {
      this.batchGeneratePdfVisible = false;
      this.batchGeneratePdfIds = "";
    },
    /* 结算状态更新确认 */
    handleSettlementStatusUpdateConfirm() {
      const param = {
        invoiceIds: this.selections.map((item) => item.id),
        settlementStatus:
          this.selections[0].settlementStatus === "SETTLED"
            ? "UNSETTLED"
            : "SETTLED",
      };
      updateInvoiceSettlementStatus(param).then((res) => {
        if (res.success) {
          this.selections = [];
          this.settlementStatusUpdateVisible = false;
          this.handleGetInvoiceData();
          this.toast("修改成功", "success");
        }
      });
    },
    /* 批量生成PDF文件确认 */
    handleBatchGeneratePdfConfirm() {
      const param = {
        invoiceIds: this.selections.map((item) => item.id),
      };
      batchGeneratePdf(param).then((res) => {
        this.selections = [];
        this.batchGeneratePdfVisible = false;
        this.handleGetInvoiceData();
        this.toast(res.message, "success");
      });
    },
    /* 编辑发票说明 */
    handleInvoiceExplain(invoice) {
      this.explainWarning = false;
      this.editVisibleExplain = true;
      this.invoiceExplainUpdateIds = invoice.id;
      this.invoiceExplain = invoice.invoiceExplain;
    },
    /* 批量编辑发票说明 */
    handleEditorExplainBatch() {
      // 选中数量检查
      if (this.selections.length === 0) {
        this.toast("请选择需要修改编辑说明的数据！", "warning");
        return;
      }
      this.editVisibleExplain = true;
      let explains = new Set();
      this.selections.forEach((item) => {
        explains.add(item.invoiceExplain);
      });
      this.explainWarning = explains.size > 1;
      explains = Array.from(explains).filter(
        (item) => item != null && item != undefined && item != ""
      );
      this.invoiceExplain = explains.length > 0 ? explains[0] : "";
      this.invoiceExplainUpdateIds = this.selections.map((item) => item.id);
    },
    /* 发票说明编辑确认 */
    updateInvoiceExplain() {
      const param = {
        ids: this.invoiceExplainUpdateIds,
        data: {
          invoiceExplain: this.invoiceExplain,
        },
      };
      invoiceExplainUpdate(param).then((res) => {
        if (res.success) {
          this.editVisibleExplain = false;
          this.handleGetInvoiceData();
          this.toast("编辑成功", "success");
        }
      });
    },
    /* 发票说明编辑取消 */
    updateInvoiceExplainCancel() {
      this.editVisibleExplain = false;
      this.invoiceExplain = "";
      this.invoiceExplainUpdateIds = [];
    },
    /* 发票清单详情 */
    async handleInvoiceLists(invoice) {
      const { success, data } = await getInvoiceListsById(invoice.id);
      if (success) {
        this.invoiceListVisible = true;
        // 前端排序 this.invoiceListData
        this.invoiceListData = data.sort((a, b) => {
          return a.id - b.id;
        });
        this.invoiceListPageSize = invoice.listTotal;
        this.invoiceListDelete = invoice;
        // 未匹配数组
        this.invoiceListUnmatched = [];
        const pageIndexes = this.invoiceListData.map((list) => list.pageIndex);
        for (let i = 1; i <= invoice.listTotal; i++) {
          if (!pageIndexes.includes(i.toString())) {
            this.invoiceListUnmatched.push(i);
          }
        }
      } else {
        this.toast("发票清单获取失败！", "error");
      }
    },
    /* 验真状态颜色 */
    handleVerifyAndSealStatusColor(status) {
      switch (status) {
        case "FAILURE":
        case "VF":
          return "#f5222d";
        case "VS":
          return "#09bb07";
        case "CHECKING":
          return "#FF7A08";
        default:
          return "#666666";
      }
    },
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.search.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.search.page = value;
      this.cancelDeleteBatch();
      this.handleQuery();
    },

    // 查验发票
    async handleVerifyInvoice() {
      if (this.selections.length < 1) {
        this.toast("请选择要查验的发票！", "warning");
        return;
      }

      const { success } = await reqVerifyInvoice({
        invoiceIds: this.selections.map((item) => item.id),
      });

      if (success) {
        this.toast("查验发票请求已提交。", "success");
      }
    },

    /* 发票删除弹窗 */
    handleInvoiceBatchDelete() {
      if (this.selections.length == 0) {
        this.toast("请选择要删除的数据！", "warning");
        return;
      }
      this.invoiceBatchDeleteVisible = true;
    },

    /* 批量推送 */
    handleInvoicePushBatch() {
      if (this.selections.length == 0) {
        this.toast("请选择要推送的数据！", "warning");
        return;
      }
      let is = this.selections.every((item) => {
        return (
          item.invoiceType == "FullEleSpecInvoice" ||
          item.invoiceType == "FullEleGenerInvoice"
        );
      });
      if (is) {
        this.pushBatch();
      } else {
        this.invoiceBatchPushVisible = true;
      }
    },
    /* 批量删除选中票据 */
    async deleteBatch() {
      let ids = this.selections.map((i) => i.id);
      let param = {
        invoiceIds: ids,
        invoiceLabel: "ordinary",
      };
      const { success } = await deleteInvoiceByIds(param);
      if (success) {
        this.toast("删除成功！", "success", () => {
          this.invoiceBatchDeleteVisible = false;
          this.handleQuery();
          this.cancelDeleteBatch();
        });
      }
    },
    /* 批量推送选中票据 */
    async pushBatch() {
      let ids = this.selections.map((i) => i.id);
      let param = {
        invoiceIds: ids,
        invoiceLabel: "ordinary",
      };
      const { success } = await pushInvoiceByIds(param);
      if (success) {
        this.toast("推送成功！", "success");
        this.handleQuery();
        this.selections = [];
        this.invoiceBatchPushVisible = false;
        this.$refs.invoiceCenterTable.clearSelection();
      }
    },
    /* 取消选中 */
    cancelDeleteBatch() {
      this.selections = [];
      this.invoiceBatchDeleteVisible = false;
      this.$refs.invoiceCenterTable.clearSelection();
    },
    /* 取消选中 */
    async cancelPushBatch() {
      this.selections = this.selections.filter((item) => {
        return (
          item.invoiceType == "FullEleSpecInvoice" ||
          item.invoiceType == "FullEleGenerInvoice"
        );
      });
      if (this.selections.length) {
        this.pushBatch();
      }
      console.log(this.selections, "---this.selectionsdsgfdsg ---");

      this.selections = [];
      this.invoiceBatchPushVisible = false;
      this.$refs.invoiceCenterTable.clearSelection();
    },
    /* 下载发票文件 */
    async handleDownFile(callback) {
      if (this.selections.length == 0) {
        this.toast("请勾选需要下载的信息", "warning");
        return;
      }

      this.downloadInBatchesVisible = true;
    },
    // 确定下载
    async handlerDownloadInBatches() {
      this.dlFileParam.invoiceIds = this.selections.map((item) => item.id);
      const rsp = await invoiceDownload(this.dlFileParam);
      this.downloadFile(rsp);
      this.downloadInBatchesVisible = false;
    },

    /* 取消导出确认提示框 */
    cancelExportConfirmation() {
      this.exportConfirmation = false;
    },
    /* 展示导出设置弹框 */
    handleExportSettings() {
      this.exportDrawer = true;
    },
    /* 导出确认提示框 */
    handleExportConfirmation() {
      let fields = false;
      Object.keys(this.columns).forEach((key) => {
        if (this.columns[key].visible) {
          fields = true;
        }
      });
      if (!fields) {
        this.toast("至少选中一个字段", "warning");
        return;
      }
      if (this.selections.length != 0) {
        // 已勾选
        this.exportQuantity = this.selections.length;
      } else {
        // 未勾选
        this.exportQuantity = this.total;
      }
      this.exportConfirmation = true;
    },
    /* 批量导出 */
    async handleExportBatch() {
      if (this.exportQuantity == 0) {
        this.toast("导出失败，暂无可导出的数据", "warning");
        return;
      }
      let ids = [];
      if (this.selections.length != 0) {
        // 已勾选
        ids = this.selections.map((i) => i.id);
      }
      this.exportLoading = true;
      this.search.invoiceIds = ids;
      this.search.columns = this.exportColumns; // 导出指定列
      const rsp = await handleExportBatch(this.search);
      // console.log(rsp, '----rsp----');
      this.exportLoading = false;
      this.exportConfirmation = false;
      this.downloadFile(rsp);
    },
    //初始化剩余喵点
    async getCurrencyInformation() {
      const resList = await Promise.all([
        getCurrency(),
        listTntConfig({
          codes: [
            "SYS_VERIFY_CURRENCY_SPENT",
            "INV_RECEIVE_VERIFY_SWITCH",
            "SYS_RECOGNITION_CURRENCY_SPENT",
          ],
        }),
      ]);

      this.currency.currencyCash = resList[0].data.cash;
      this.currency.verifySpend = resList[1].data["SYS_VERIFY_CURRENCY_SPENT"];
      this.currency.verifySwitch = resList[1].data["INV_RECEIVE_VERIFY_SWITCH"];
      this.currency.recognizeSpend =
        resList[1].data["SYS_RECOGNITION_CURRENCY_SPENT"];
      this.currency.recognizeSwitch = "Y";
    },
    handleColumnSetting(invoiceField, itemField) {
      this.exportColumns = [];
      this.search.exportItems = false;
      // 收集发票列
      Object.keys(invoiceField).forEach((key) => {
        if (invoiceField[key].visible) {
          this.exportColumns.push(key);
        }
      });
      // 收集明细列
      Object.keys(itemField).forEach((key) => {
        if (itemField[key].visible) {
          if (!this.search.exportItems) this.search.exportItems = true;
          this.exportColumns.push(key);
        }
      });
      this.exportDrawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/select.scss";
.tabs_box {
  position: relative;
}
.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          margin-left: 20px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  margin: 24px;
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}
.type_invoice {
  ::v-deep .el-select__tags {
    flex-wrap: nowrap;
    max-width: 88px;
  }
}
.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.kp_title {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
.invoiceImageVisibleHeight {
  ::v-deep .el-dialog {
    height: 100%;
    margin: 0;
    margin-top: 1vh;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 26px;
  }
}
.order_sort {
  display: flex;
  justify-content: space-between;
}
.incvoice_open_goods_list {
  table {
    width: 100%;
  }
}
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
</style>
